<template>
  <div>
    <time-picker-component
      :lableName="$t('Pick a time')"
      :timeValue="item.Value"
      :timeOptions="{
        timeClearable: !item.Required,
        timeDisabled:  $helpers.itemNeedsDisable(item.ReadOnly, needsDisable, editItemAdmin),
        timeFieldValidation: item.FieldValidation,
        timeRules: item.FieldValidationFuntion,
        timeAutoSelectCurrent: item.AutoSelectCurrent,
        showSeconds: item.ShowSeconds,
      }"
      @changedTime="changeTime"
    />

    <template v-if="$store.getters.editMode">
      <hr class="mt-5" />
      <v-row no-gutters class="my-5">
        <v-col cols="12">
          <v-layout column>
            <v-text-field
              v-model.number="item.colWidth"
              hide-details="auto"
              type="number"
              max="12"
              min="1"
              dense
              :label="$t('Column width')"
              class="mw-w"
            ></v-text-field>
            <v-switch
              v-model="item.Required"
              class="float-left mr-3"
              hide-details="auto"
              :label="$t('Field is required')"
            ></v-switch>
            <v-switch
              v-model="item.ReadOnly"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('Read-only')"
            ></v-switch>
            <v-switch
              hide-details="auto"
              class="float-left mr-3"
              v-model="item.ShowSeconds"
              :label="$t('Show seconds')"
            ></v-switch>
            <v-switch
              v-if="item.Type == 'time'"
              v-model="item.AutoSelectCurrent"
              :label="$t('Auto select time')"
              class="float-left mr-3"
              hide-details="auto"
            >
            </v-switch>
            <v-switch
              v-model="item.FieldValidation"
              @change="$helpers.changeFieldValidation(item)"
              class="float-left mr-3"
              :label="$t('Field validation')"
            >
            </v-switch>
            <v-row no-gutters>
              <v-col cols="6">
                <v-combobox
                  v-if="item.FieldValidation"
                  :items="$validation.getAllValidationNames()"
                  class="float-left mr-3"
                  :label="$t('Rule')"
                  v-model="item.FieldValidationFuntion"
                  clearable
                  @click:clear="item.FieldValidationFuntion = ''"
                >
                </v-combobox>
              </v-col>
            </v-row>
          </v-layout>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    editItemAdmin: {
      type: Boolean,
    }
  },
  methods: {
    changeTime(time) {
      this.item.Value = time;
    },
  },
};
</script>

<style scoped>
.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
</style>